import logo_2 from '../src/components/container/logo_2.png';
import './App.css';
import flamingo from "../src/components/container/flamingo.png";

function App() {
  return (
    <div className="App row">
      <header className="App-header col-md-12">
        <img className="bird" src={flamingo}/>
        <img src={logo_2} className="App-logo" alt="logo" />  
      </header>
      <p className="text">Born to stand out!</p> 
<div className="info">
  <p>Erika Stahl |&nbsp; +34 619 76 12 93 |&nbsp; <a className="email" href="mailto:info@e@akiredo.com">e@akiredo.com</a></p>
</div>
    </div>
  );
}

export default App;
